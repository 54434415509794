<template>
  <div>
    <b-row>
      <b-col>
        <b-media no-body>
          <b-media-aside
            class="mr-2"
          >
            <b-avatar
              size="48"
              variant="light-info"
            >
              <feather-icon
                size="24"
                icon="UserIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">
              {{ dataItem.customer ? dataItem.customer : spliteText(dataItem.company_name) }}
            </h4>
            <b-card-text class="font-small-3 mb-0">
              <div style="margin-bottom: 0.5%">
                <b-badge
                  variant="light-secondary"
                  style="margin-top: 1%"
                >
                  <feather-icon icon="PhoneCallIcon" />
                  {{ getCrcTypes(dataItem.id_com_crc_type) }}
                </b-badge>
              </div>
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col>
        <div
          v-if="dataItem.status !== '1'"
          class="text-right"
        >
          <b-button
            variant="primary"
            @click="newModal"
          >
            <FeatherIcon icon="PlusIcon" /> Yeni Durum
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
        class="mt-2"
      >
        <b-row>
          <b-col>
            <b-list-group>
              <list-item
                title="Telefon"
                :value="dataItem.phone"
              />
              <list-item
                title="Lokasyon"
                :value="dataItem.location"
              />
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Marka
                </div>
                <b-badge
                  variant="light-warning"
                >
                  <feather-icon icon="CodesandboxIcon" />
                  {{ dataItem.brand ? dataItem.brand : '-' }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col>
            <b-list-group>
              <list-item
                title="İlgili Kişi"
                :value="dataItem.customer_related_person ? dataItem.customer_related_person : '-'"
              />
              <list-item
                title="İlgili Telefon"
                :value="dataItem.customer_related_person_phone ? dataItem.customer_related_person_phone : '-'"
              />
            </b-list-group>
          </b-col>
          <b-col>
            <b-list-group>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Durum
                </div>
                <b-badge
                  :variant="dataItem.status_variant"
                >
                  <feather-icon :icon="dataItem.status_icon" />
                  {{ dataItem.crc_status }}
                </b-badge>
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Kapanış Kodu / Tarihi
                </div>
                {{ dataItem.close_code ? dataItem.close_code : '-' }}
                <b-badge
                  v-if="dataItem.close_date"
                  variant="light-success"
                >
                  <feather-icon icon="CalendarIcon" />
                  {{ moment(dataItem.close_date).format('DD.MM.YYYY') }}
                </b-badge>
              </b-list-group-item>
              <list-item
                title="İlgili Danışman"
                :value="dataItem.service_user ? dataItem.service_user : '-'"
              />
            </b-list-group>
          </b-col>
        </b-row>
        <div
          v-if="dataItem.id_com_interview"
          class="mt-1"
        >
          <feather-icon
            icon="MessageCircleIcon"
            class="text-primary"
          />
          <a
            :href="'/interviews/view/'+dataItem.interview_number"
            target="_blank"
          ><b class="text-primary">{{ dataItem.interview_number }}</b></a> numaralı görüşme notu oluşturuldu.
        </div>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {
  BRow, BCol, BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCardText, BBadge, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'
import ListItem from '@/views/Customers/view/ListItem.vue'

export default {
  name: 'CrcViewHeader',
  components: {
    ListItem,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardText,
    BBadge,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  props: {
    newModal: {
      type: Function,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['crc/dataItem']
    },
    crcTypes() {
      return this.$store.getters['crc/crcTypes']
    },
  },
  watch: {

  },
  created() {

  },
  methods: {
    getCrcTypes(id) {
      const found = this.crcTypes.find(item => item.id == id)
      return found ? found.title : null
    },
    spliteText(metin) {
      return (metin && metin.length > 0) && metin.length > 20
        ? `${metin.slice(0, 20)}...`
        : metin
    },
  },
}
</script>
