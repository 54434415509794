<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" />
          Genel Bilgiler
        </template>
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col>
                <crc-view-header :new-modal="showModal" />
              </b-col>
              <b-col
                v-if="dataItem.id_com_crc_status === '2' && dataItem.calldate"
                cols="12"
                md="12"
              >
                <b-alert
                  show
                  variant="warning"
                  class="m-2"
                >
                  <div class="alert-body text-center">
                    <feather-icon icon="AlertCircleIcon" /> <b>{{ moment(dataItem.calldate).format('DD.MM.YYYY') }}</b> tarihinde tekrar aranacak olarak belirlenmiş.
                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </b-card-body>
          <b-alert
            v-if="dataList.length < 1"
            show
            variant="info"
            class="m-2"
          >
            <div class="alert-body text-center">
              Hiç kayıt bulunmuyor 👏
              <div class="mt-2">
                <b-button
                  variant="primary"
                  size="sm"
                  class="mb-1"
                  @click="showModal"
                >
                  <feather-icon icon="PlusIcon" />
                  Yeni durum
                </b-button>
              </div>
            </div>
          </b-alert>
          <template v-else>
            <b-table
              responsive="sm"
              :fields="fields"
              :items="dataList"
              striped
            >
              <template #cell(crc_status)="data">
                <b-badge :variant="data.item.variant">
                  <feather-icon :icon="data.item.icon" />
                  {{ data.item.crc_status }}
                </b-badge>
                <div v-if="data.item.id_com_crc_status === '2' && data.item.calldate">
                  <small><b class="text-info">{{ moment(data.item.calldate).format('DD.MM.YYYY') }} </b> tarihinde arama bekliyor</small>
                </div>
              </template>
              <template #cell(username)="data">
                <b>{{ data.item.username }}</b>
                <div>
                  <small class="text-secondary"><feather-icon icon="SaveIcon" /> {{ moment(data.item.created).format('DD.MM.YYYY HH:mm:ss') }}</small>
                </div>
              </template>
            </b-table>
            <b-card-footer>
              <b-pagination
                v-model="currentPage"
                :total-rows="dataCounts"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-card-footer>
          </template>
        </b-card>
        <b-modal
          v-model="modal.status"
          title="Yeni Durum Oluştur"
          centered
          size="lg"
          no-close-on-backdrop
          no-close-on-esc
        >
          <validation-observer ref="simpleRules">
            <crc-lines-item-form />
          </validation-observer>
          <template #modal-footer>
            <b-button
              variant="secondary"
              @click="modal.status=false"
            >
              İptal
            </b-button>
            <b-button
              variant="success"
              :disabled="modal.saving"
              @click="saveModalData"
            >
              <feather-icon
                v-if="!modal.saving"
                icon="SaveIcon"
              />
              <b-spinner
                v-if="modal.saving"
                small
              />
              Kaydet
            </b-button>
          </template>
        </b-modal>
      </b-tab>
      <b-tab v-if="car">
        <template #title>
          <feather-icon icon="DiscIcon" />
          <span>Araç Kartı</span>
        </template>
        <car />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Müşteri Kartı</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BAlert, BModal, BSpinner, BTabs, BTab, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, localize } from 'vee-validate'
import Car from '@/views/Crm/View/Car.vue'
import Customer from '@/views/Crm/View/Customer.vue'
import CrcLinesItemForm from '@/views/Crm/Crc/Components/ItemForm.vue'
import CrcViewHeader from '@/views/Crm/Crc/Components/viewHeader.vue'

const tableName = 'com_crc_lines'
export default {
  name: 'CrcLines',
  components: {
    CrcViewHeader,
    CrcLinesItemForm,
    Customer,
    Car,
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BAlert,
    BModal,
    BSpinner,
    BTabs,
    BTab,
    ValidationObserver,
    BRow,
    BCol,
    BBadge,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'content',
          label: 'İçerik',
          thClass: 'width-400 text-nowrap',
          tdClass: 'width-400 text-nowrap',
        },
        {
          key: 'crc_status',
          label: 'Durum',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },

        {
          key: 'username',
          label: 'Danışman',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.content AS content`,
          `${tableName}.created AS created`,
          `${tableName}.id_com_crc_status AS id_com_crc_status`,
          `${tableName}.calldate AS calldate`,
          'com_user.name AS username',
          'com_crc_status.title AS crc_status',
          'com_crc_status.status AS status',
          'com_crc_status.variant AS variant',
          'com_crc_status.icon AS icon',
        ],
        where: {},
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['crc/dataItem']
    },
    modalData() {
      return this.$store.getters['crcLines/dataItem']
    },
    dataList() {
      return this.$store.getters['crcLines/dataList']
    },
    dataCounts() {
      return this.$store.getters['crcLines/dataCounts']
    },
    saveData() {
      return this.$store.getters['crcLines/dataSaveStatus']
    },
    dataLoading() {
      return this.$store.getters['crcLines/dataLoading']
    },
    modal() {
      return this.$store.getters['crcLines/modalData']
    },
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
    dataItem(val) {
      this.getCustomer(val.id_com_customer)
      this.getCar(val.id_com_cars)
    },
  },
  created() {
    this.getDataList()
    this.getDataItem()
    localize('tr')
  },
  methods: {
    getDataItem() {
      this.$store.dispatch('crc/getDataItem', this.$route.params.id_crc)
    },
    getDataList() {
      this.dataQuery.where.id_com_crc = this.$route.params.id_crc
      this.$store.dispatch('crcLines/getDataList', this.dataQuery)
    },
    showModal() {
      this.modal.status = true
      this.$store.commit('crcLines/RESET_DATA_ITEM')
    },
    saveModalData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.modal.saving = true
          this.modalData.id_com_crc = this.$route.params.id_crc
          this.$store.dispatch('crcLines/saveData', this.modalData)
            .then(response => {
              if (response.status) {
                this.modal.status = false
                this.getDataItem()
              }
              this.modal.saving = false
            })
        }
      })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getCar(id) {
      this.$store.dispatch('cars/carView', id)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
